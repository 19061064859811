<template>
  <div>
    <ul class="check" v-if="dataList && dataList.length > 0">
      <li v-if="selectAll">
        <label>
          <input
            type="checkbox"
            v-model="selectedAll"
            @change="selectAllChange"
            :disabled="disabled"
          />
          <i></i>
          <div class="label">전체</div>
        </label>
      </li>
      <li v-for="(data, idx) in dataList" :key="`${code}_${idx}`">
        <label>
          <input
            type="checkbox"
            v-model="selectedList"
            :value="data.comCode"
            :disabled="disabled"
          />
          <i></i>
          <div
            class="label"
            :style="
              textColor && !disabled ? 'color:' + data.colorValue + ';' : ''
            "
          >
            {{ data.comName }}
          </div>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import { commonCodesGetCommonCode } from "@/utils/commonCodes";

const receiveList = [
  { comCode: "Y", comName: "수신" },
  { comCode: "N", comName: "미수신" },
];
const isOutList = [
  { comCode: "N", comName: "회원" },
  { comCode: "Y", comName: "탈회회원" },
];

export default {
  name: "CheckBoxRow",
  mounted() {
    this.selectedList = this.selected;
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    exceptCode: {
      type: Array,
      default: null,
    },
    code: {
      type: String,
      default: null,
    },
    customCode: {
      type: Array,
      default: null,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    isTypeList: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowEmptyValue: {
      type: Boolean,
      default: true,
    },
    textColor: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value() {
      this.selectedList = this.value;
    },
    selectedList(newVal, oldVal) {
      if (!this.allowEmptyValue) {
        if (this.selectedList.length === 0) {
          alert("최소 1개 이상 선택해야 합니다.");
          this.$nextTick(() => {
            this.selectedList = oldVal;
            this.$emit("input", this.selectedList);
          });
          return;
        }
      }
      this.selectedAll = this.selectedList.length === this.dataList.length;
      this.$emit("input", this.selectedList);
    },
  },
  data() {
    return {
      selectedList: [],
      selectedAll: false,
    };
  },
  computed: {
    dataList() {
      if (this.code) {
        return commonCodesGetCommonCode(this.code, true).filter((data) => {
          if (this.exceptCode && this.exceptCode.includes(data.comCode)) {
            return false;
          }
          return true;
        });
      } else if (this.isTypeList && this.isTypeList === "receive") {
        return receiveList;
      } else if (this.isTypeList && this.isTypeList === "isOut") {
        return isOutList;
      } else if (this.customCode) {
        return this.customCode;
      } else {
        return [];
      }
    },
  },
  methods: {
    selectAllChange() {
      if (this.selectedAll) {
        this.selectedList = this.dataList.map((data) => data.comCode);
      } else {
        this.selectedList = [];
      }
    },
  },
};
</script>

<style scoped></style>
