<template>
  <div>
    <ejs-dialog
      ref="couponPubPopupDialog"
      header="모바일 쿠폰 발행"
      :allowDragging="true"
      :showCloseIcon="true"
      width="400"
      height="670"
      :animationSettings="{ effect: 'None' }"
      :close="dialogClose"
      :position="{ x: 'center' }"
      :enableResize="false"
      :isModal="true"
    >
      <div class="window mobileCouponPub">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article id="section-body" class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">쿠폰선택</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title required">쿠폰종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="couponKind"
                                        v-model="selectedKindId"
                                        :dataSource="couponKindNameList"
                                        :allowFiltering="false"
                                        :fields="dropdownCouponKindCodesOptions"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">할인종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item text">
                                    {{
                                      getDcKindName(selectedCouponInfo.dcKind)
                                    }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">타인양도가능</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label for="giveFlagT">
                                          <input
                                            type="radio"
                                            id="giveFlagT"
                                            :value="true"
                                            v-model="
                                              selectedCouponInfo.giveFlag
                                            "
                                          />
                                          <i></i>
                                          <div class="label">가능</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label for="giveFlagF">
                                          <input
                                            type="radio"
                                            id="giveFlagF"
                                            :value="false"
                                            v-model="
                                              selectedCouponInfo.giveFlag
                                            "
                                          />
                                          <i></i>
                                          <div class="label">불가능</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">할인방법</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title required">할인방법</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="dcMethod"
                                        v-model="selectedCouponInfo.dcMethod"
                                        :dataSource="dcMethodCodes"
                                        :allowFiltering="false"
                                        :fields="dropdownListCodesOptions"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div
                                :class="[
                                  'title',
                                  {
                                    required:
                                      selectedCouponInfo.dcMethod !== 'PAYMT',
                                  },
                                ]"
                              >
                                금액/할인율
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        ref="dcAmt"
                                        v-model="selectedCouponInfo.dcAmt"
                                        :disabled="
                                          selectedCouponInfo.dcMethod ===
                                          'PAYMT'
                                        "
                                        :allow-dot="
                                          selectedCouponInfo.dcMethod === 'RATE'
                                        "
                                        :propMaxLength="
                                          selectedCouponInfo.dcMethod === 'AMT'
                                            ? 13
                                            : 3
                                        "
                                        :max="
                                          selectedCouponInfo.dcMethod === 'RATE'
                                            ? 100
                                            : undefined
                                        "
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div
                                :class="[
                                  'title',
                                  {
                                    required:
                                      selectedCouponInfo.dcMethod === 'PAYMT',
                                  },
                                ]"
                              >
                                요금코드
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="paymtCode"
                                        v-model="selectedCouponInfo.paymtCode"
                                        :enabled="
                                          selectedCouponInfo.dcMethod ===
                                          'PAYMT'
                                        "
                                        :dataSource="paymtCodes"
                                        :allowFiltering="false"
                                        :fields="dropdownListCodesOptions"
                                        cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-03">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">사용제한</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title required">사용매장</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="storeCode"
                                        v-model="selectedCouponInfo.storeCode"
                                        :dataSource="storeCodes"
                                        :allowFiltering="false"
                                        :fields="dropdownListCodesOptions"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">영업구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group select">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        v-model="selectedCouponInfo.bsnCode"
                                        :dataSource="bsnCodes"
                                        :allowFiltering="false"
                                        :fields="dropdownListCodesOptions"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">사용가능시간</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        v-model="selectedCouponInfo.startTime"
                                        :format="'HH:mm'"
                                      />
                                    </div>
                                  </li>
                                  <li class="item text">~</li>
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        v-model="selectedCouponInfo.endTime"
                                        :format="'HH:mm'"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">중복할인가능</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="
                                              selectedCouponInfo.dplctDcFlag
                                            "
                                          />
                                          <i></i>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-04">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">발행 정보</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title">발행 인원수</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        v-model="totalMembers"
                                        :disabled="true"
                                      ></input-number>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">1인당 발행매수</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        v-model="pubSize"
                                        :default-value1213="1"
                                        :min="1"
                                      ></input-number>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">발행일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-date
                                        v-model="pubDate"
                                        :format="'YYYY-MM-DD'"
                                      ></input-date>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">유효기간</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-date-range
                                        v-model="expirDateRange"
                                      ></input-date-range>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section
                  :class="[
                    'article-section',
                    'section-05',
                    'accordion',
                    { close: !isCouponSendTextVisible },
                  ]"
                >
                  <div class="section-header">
                    <div class="header-left">
                      <div
                        class="header-title"
                        @click="handleCouponSendTextHeaderClick"
                      >
                        모바일 전송 문구
                      </div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title">SMS 전송</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="
                                              selectedCouponInfo.smsSendFlag
                                            "
                                          />
                                          <i></i>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">SMS 전송문구</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                        v-model="
                                          selectedCouponInfo.smsDefaultWords
                                        "
                                        :rows="10"
                                        :disabled="true"
                                      />
                                    </div>
                                  </li>
                                </ul>
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                        placeholder="추가 SMS 메시지 전송문구"
                                        v-model="
                                          selectedCouponInfo.smsAditWords
                                        "
                                        :rows="10"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              class="field image"
                              v-if="selectedCouponInfo.imageURL"
                            >
                              <div class="title">이미지</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <div
                                        class="item"
                                        :style="{
                                          backgroundImage:
                                            'url(' +
                                            selectedCouponInfo.imageURL +
                                            ')',
                                          width: '200px',
                                          height: '140px',
                                        }"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="publish keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-shortcut-button="true"
                  :is-key-color="true"
                  v-on:click.native="onClickCouponPub"
              >발행
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="dialogClose">닫기</erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import InputTextarea from "@/components/common/text/InputTextarea";
import InputNumber from "@/components/common/InputNumber";
import InputTime from "@/components/common/datetime/InputTime";
import { validateFormRefs } from "@/utils/formUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import commonMixin from "@/views/layout/mixin/commonMixin";
import Vue from "vue";
import AsyncComputed from "vue-async-computed";
import GolfERPService from "@/service/GolfERPService";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import moment from "moment";
import InputDate from "@/components/common/datetime/InputDate";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { numberWithCommas } from "@/utils/number";
import ErpButton from "@/components/button/ErpButton.vue";

Vue.use(AsyncComputed);

const couponInfoInitData = Object.freeze({
  dcKind: null,
  giveFlag: true,
  dcMethod: null,
  dcAmt: null,
  paymtCode: null,
  storeCode: null,
  bsnCode: "%",
  startTime: null,
  endTime: null,
  dplctDcFlag: true,
  smsSendFlag: true,
  smsAditWords: null,
});

export default {
  name: "CouponPubPopup",
  components: {
    InputTextarea,
    InputDate,
    InputDateRange,
    InputTime,
    InputNumber,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      isCouponSendTextVisible: false,
      pubSize: 1,
      pubDate: moment().format("YYYY-MM-DD"),
      expirDateRange: {
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
      },
      isCouponImageUploadedAndNotSaved: false,
      dcKindCodes: commonCodesGetCommonCode("DC_KIND"),
      dcMethodCodes: commonCodesGetCommonCode("DC_METHOD"),
      couponKindCodes: commonCodesGetCommonCode("COUPON_KIND"),
      paymtCodes: commonCodesGetCommonCode("PAYMT_CODE"),
      dropdownListCodesOptions: { text: "comName", value: "comCode" },
      dropdownCouponKindCodesOptions: { text: "couponName", value: "kindId" },
      selectedCouponInfo: JSON.parse(JSON.stringify(couponInfoInitData)),
      kindId: null,
      oldCouponInfo: null,
      buttons: {
        browse: "등록",
      },
      selectedKindId: null,
      searchTotalMemberOptions: [],
      couponKindNameList: [],
      couponKindList: [],
      totalMembers: 0,
      validateRefList: {
        couponKind: { required: true },
      },
    };
  },
  beforeDestroy() {
    if (this.isCouponImageUploadedAndNotSaved) {
      this.deleteCouponFile();
    }
  },
  watch: {
    selectedKindId(val) {
      if (val) {
        this.selectedCouponInfo = this.couponKindList.find(
          (data) => data.kindId === val
        );
        this.expirDateRange.from = moment().format("YYYY-MM-DD");
        if (this.selectedCouponInfo.expirMonthCnt) {
          this.expirDateRange.to = moment()
            .add(this.selectedCouponInfo.expirMonthCnt, "M")
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        } else {
          this.expirDateRange.to = moment().format("YYYY-MM-DD");
        }
        this.oldCouponInfo = JSON.parse(
          JSON.stringify(this.selectedCouponInfo)
        );
      }
    },
    selectedCouponInfo: {
      deep: true,
      handler(data) {
        if (data.dcMethod === "PAYMT") {
          data.dcAmt = 0;
        }
        if (this.oldCouponInfo) {
          if (this.oldCouponInfo.dcMethod !== data.dcMethod) {
            data.dcAmt = 0;
          }
        }
        this.oldCouponInfo = JSON.parse(JSON.stringify(data));
      },
    },
  },
  asyncComputed: {
    async storeCodes() {
      const response = await GolfERPService.fetchStores(true);
      return commonCodesGetCommonCode("STORE_CODE").filter((data) => {
        return response.find((rData) => rData.code === data.comCode);
      });
    },
  },
  computed: {
    bsnCodes() {
      const result = commonCodesGetCommonCode("BSN_CODE").filter(
        (data) => data.comCode !== "CLOSE"
      );
      result.unshift({ comCode: "%", comName: "전체" });
      return result;
    },
    isCreate() {
      if (this.kindId) {
        return false;
      }
      return true;
    },
  },
  methods: {
    validateFormRefs,
    getDcKindName(data) {
      if (!data) {
        return null;
      }
      return this.dcKindCodes.find((dcData) => dcData.comCode === data).comName;
    },
    initCouponPubData() {
      this.selectedCouponInfo = JSON.parse(JSON.stringify(couponInfoInitData));
      GolfErpAPI.fetchCouponPubDetailList().then((data) => {
        this.couponKindList = data;
        this.couponKindNameList = data.map((data) => {
          return { kindId: data.kindId, couponName: data.couponName };
        });
      });
    },
    showPopup(selected, result) {
      this.totalMembers = selected;
      this.searchTotalMemberOptions = result;
      this.initCouponPubData();
      this.$refs.couponPubPopupDialog.show();
    },
    dialogClose() {
      this.$emit("popupClosed");
    },
    onClickCouponPub() {
      if (this.totalMembers < 1) {
        return this.errorToast("발행가능한 인원이 없습니다");
      }
      if (
        this.selectedCouponInfo.dcMethod === "PAYMT" &&
        !this.selectedCouponInfo.paymtCode
      ) {
        return this.errorToast("요금코드를 지정하지 않았습니다");
      }
      if (
        this.selectedCouponInfo.dcMethod !== "PAYMT" &&
        this.selectedCouponInfo.dcAmt < 1
      ) {
        return this.errorToast(
          this.$t("main.validationMessage.nonNumZeroMessage", [
            "할인금액 또는 할인율",
          ])
        );
      }

      if (!this.validateFormRefs(this.validateRefList)) {
        return;
      }

      const body = {
        searchTotalMemberOptions: this.searchTotalMemberOptions,
        couponPubRequest: {
          couponUnit: this.selectedCouponInfo,
          pubDate: this.pubDate,
          pubSize: this.pubSize,
          startDate: this.expirDateRange.from,
          expirDate: this.expirDateRange.to,
        },
      };

      GolfErpAPI.postCouponPub(body)
        .then((res) => {
          if (res.status === "OK") {
            const { excludeCount, totalCount } = res.data;

            this.infoToast(
              excludeCount !== totalCount
                ? `총 ${numberWithCommas(
                    totalCount
                  )}건 중 핸드폰 번호가 없는 ${numberWithCommas(
                    excludeCount
                  )}건을 제외하여 발행에 성공했습니다`
                : "핸드폰 번호가 존재하지 않아 발행에 실패했습니다"
            );
          } else {
            this.errorToast("발행에 실패했습니다");
          }

          this.dialogClose();
        })
        .catch((err) => {
          this.errorToast("발행에 실패했습니다 => ".concat(err));
        });
    },
    handleCouponSendTextHeaderClick() {
      this.isCouponSendTextVisible = !this.isCouponSendTextVisible;
      this.onScrollToEnd();
    },
    onScrollToEnd() {
      const container = document.querySelector("#section-body");
      this.$nextTick(() => {
        container.scrollTop = container.scrollHeight;
      });
    },
  },
};
</script>
