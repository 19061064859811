<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="detailVisitInfoInCouponMemberSearchPopup"
      header="내장정보 상세"
      allowDragging="true"
      showCloseIcon="true"
      width="1270"
      height="800"
      isModal="true"
      @close="onDetailVisitInfoInCouponMemberSearchPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">내장정보 상세 목록</div>
                      <div class="header-title header-sub-title">
                        ({{ searchOptions.bsnDateFrom }} ~ {{ searchOptions.bsnDateTo }})
                      </div>
                      <div class="header-caption">
                        [{{
                          numberWithCommas(
                            detailVisitInfoLength
                          )
                        }}건]
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="print">
                          <erp-button
                              button-div="FILE"
                              @click.native="excel"
                          >
                            Excel
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="detailVisitInfoGrid"
                        :provides="grid"
                        :columns="detailVisitInfoGridColumns"
                        :noColumnWidth="40"
                        :dataSource="detailVisitInfos"
                        :selectionSettings="selectionSettings"
                        :allowFiltering="false"
                        :allowSorting="false"
                        :allowExcelExport="true"
                        @headerCellInfo="detailVisitInfoGridHeaderCellInfo"
                        @queryCellInfo="detailVisitInfoGridQueryCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeDetailVisitInfoInCouponMemberSearchPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .body-article .section-header .header-title { margin: 2px 5px 3px 3px; }
body .body-article .section-header .header-title.header-sub-title { font-size: 14px; margin: 6px 15px 2px 0; }
</style>

<script>
import Vue from 'vue';
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import { numberWithCommas } from "@/utils/number";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Aggregate, ExcelExport, Filter, ForeignKey, Group, Page, Resize} from "@syncfusion/ej2-vue-grids";
import {openNewWindow} from "@/utils/appInfo";
import {commonCodesGetColorValue} from "@/utils/commonCodes";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "DetailVisitInfoInCouponMemberSearchPopup",
  components: {
    EjsGridWrapper,
    ErpButton
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  computed: {},
  created() {},
  mounted() {},
  data() {
    return {
      grid: [Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey, Page],
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      detailVisitInfoGridColumns: [
        {
          field: 'chkinId',
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: 'visitDate',
          headerText: '내장일자',
          width: 80,
          textAlign: 'center',
        },
        {
          field: 'dropdownMenu',
          headerText: '',
          width: 24,
          template: () => {
            return {
              template: {
                extends: Vue.component('detailVisitInfoGridDropdownTemplate', {
                  template:
                    '<div class=\'dropdownbutton\' style=\'\'><ejs-dropdownbutton :items=\'meunItems\' :select=\'onMenuSelect\'></ejs-dropdownbutton></div>',
                  props: ['detailVisitInfoGridMenuSelectedCallback'],
                  data() {
                    return {
                      meunItems: [],
                      data: {
                        data: {},
                      },
                    };
                  },
                  mounted() {
                    this.meunItems = [
                      {
                        text: '내장화면이동',
                        value: 'VISIT_PAGE_MOVE',
                      },
                    ];
                  },
                  methods: {
                    onMenuSelect: function(args) {
                      let eventParam = {
                        menu: args.item.value,
                        data: this.data,
                      };
                      this.detailVisitInfoGridMenuSelectedCallback(eventParam);
                    },
                  },
                }),
                propsData: {
                  detailVisitInfoGridMenuSelectedCallback: this.ondetailVisitInfoGridMenuSelected,
                },
              },
            };
          },
          type: 'string',
          allowFiltering: false,
        },
        {
          field: 'dwCode',
          headerText: '요일',
          width: 50,
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'DW_CODE',
        },
        {
          field: 'bsnCode',
          headerText: '영업',
          width: 50,
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'BSN_CODE',
        },
        {
          field: 'startTime',
          headerText: '시간',
          width: 50,
          textAlign: 'center',
        },
        {
          field: 'startCourse',
          headerText: '코스',
          width: 60,
          textAlign: 'center',
          isCommonCodeField: true,
          groupCode: 'COURSE_CODE',
        },
        {
          field: 'resveName',
          headerText: '예약자명',
          width: 90,
        },
        {
          field: 'grpName',
          headerText: '단체명',
          width: 90,
        },
        {
          field: 'visitName1',
          headerText: '내장객(1)',
          width: 90,
        },
        {
          field: 'visitName2',
          headerText: '내장객(2)',
          width: 90,
        },
        {
          field: 'visitName3',
          headerText: '내장객(3)',
          width: 90,
        },
        {
          field: 'visitName4',
          headerText: '내장객(4)',
          width: 90,
        },
        {
          field: 'visitName5',
          headerText: '내장객(5)',
          width: 90,
        },
        {
          field: 'insertUserName',
          headerText: '등록자',
          width: 90,
        },
        {
          field: 'insertDt',
          headerText: '등록일시',
          width: 130,
          textAlign: 'center',
        },
      ],
      detailVisitInfos: [],
      detailVisitInfoLength: 0,
      searchOptions: {
        bsnDateFrom: null,
        bsnDateTo: null,
        membershipId: null,
        bsnCodes: null,
        dwCodes: null,
        visitStartTime: null,
        visitEndTime: null,
      }
    };
  },
  methods: {
    numberWithCommas,
    showPopup(args) {
      this.searchOptions = args;

      this.getMemberReservationVisitSearch();
    },
    async getMemberReservationVisitSearch() {
      const args = {
        membershipId: this.searchOptions.membershipId,
        bsnDate: this.searchOptions.bsnDateFrom,
        bsnDateTo: this.searchOptions.bsnDateTo,
        bsnCode: this.searchOptions.bsnCodes,
        dwCode: this.searchOptions.dwCodes,
        visitStartTimeFrom: this.searchOptions.visitStartTimeFrom,
        visitStartTimeEnd: this.searchOptions.visitStartTimeEnd,
        searchDiv: "VISIT",
        isLimit: false,
      };
      const data = await GolfErpAPI.fetchMemberReservationVisitSearch(args);

      this.detailVisitInfos = data.visits;
      this.detailVisitInfoLength = data.visits.length;
    },
    excel() {
      this.$refs.detailVisitInfoGrid.excelExport();
    },
    closeDetailVisitInfoInCouponMemberSearchPopup() {
      this.$refs.detailVisitInfoInCouponMemberSearchPopup.hide();
    },
    onDetailVisitInfoInCouponMemberSearchPopupClosed() {
      this.$emit("popupClosed");
    },
    ondetailVisitInfoGridMenuSelected(args) {
      if (args.menu === 'VISIT_PAGE_MOVE' && !this.commonMixinIsButtonDisableByAuth('frontChkinVisitGet')) {
        this.visitPageMove(args.data);
      } else {
        this.errorToast(this.$t('main.popupMessage.noAuth'));
      }
    },
    visitPageMove(data) {
      let routeData = this.$router.resolve({
        name: 'frontCheckinVisit',
        query: {
          bsnDate: data.visitDate,
          timeId: data.timeId,
        },
      });
      openNewWindow(routeData);
    },
    detailVisitInfoGridHeaderCellInfo(args) {
      const {
        cell: {column: {field}},
        node,
      } = args;
      if (field === 'dropdownMenu') {
        node.classList.add(this.$t('className.grid.contextMenuArea'));
      }
    },
    detailVisitInfoGridQueryCellInfo(args) {
      const {
        column: {
          field,
        },
        cell,
        data,
      } = args;
      if (field === 'bsnCode') {
        cell.style.color = commonCodesGetColorValue(
          'BSN_CODE',
          data.bsnCode,
        );
      }
      if (field === 'resveName') {
        if (data.membershipId === this.searchOptions.membershipId) {
          cell.style.color = '#4472c4';
          cell.style.fontWeight = 'bold';
        }
      }
      for (let i = 1; i <= 5; i++) {
        const key = `visitName${i}`;
        if (field === key) {
          const visitMembershipId = `visitMembershipId${i}`;
          if (data[visitMembershipId] === this.searchOptions.membershipId) {
            cell.style.color = '#4472c4';
            cell.style.fontWeight = 'bold';
          }
        }
      }
    },
  }
};
</script>