<template>
  <div class="content-wrapper">
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section
          :class="[
            'article-section',
            'section-01',
            'accordion',
            { close: !isSearchSectionVisible },
            { open: !isMemberGridVisible },
          ]"
        >
          <div class="section-header">
            <div class="header-left">
              <div class="header-title" @click="handleSearchSectionHeaderClick">
                회원 조건 검색
              </div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="lookup keyColor">
                  <erp-button
                      button-div="GET"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      :disabled="isLoading"
                      @click.native="onSearchClick"
                  >
                    조회
                  </erp-button>
                </li>
                <li class="reset">
                  <erp-button
                      button-div="GET"
                      :is-icon-custom="true"
                      @click.native="searchInit"
                  > 초기화
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="CouponMemberSearch.shortcuts.sendSms"
                      :shortcut="{key: 'F7'}"
                      @click.native="sendSms"
                  >
                    SMS전송
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="applyCoupon">
                    쿠폰 발행
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      button-div="FILE"
                      :disabled="isCouponMemberExcelDownloading"
                      @click.native="onExcelClick"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="section-body"
            style="display: flex; flex-direction: column"
          >
            <div style="flex: 1; overflow: auto">
              <article class="body-article" style="padding: 0">
                <section class="article-section" style="padding: 0">
                  <div class="section-body" style="padding: 18px; border: none">
                    <div class="body-data outerData">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field" style="width: calc(100% + 1px)">
                              <!-- 필수 : required -->
                              <div class="title outerDataTitle">
                                <div class="outerDataTitleItem">
                                  {{
                                    vueCouponSearchNameObj.memberInfo.title
                                  }}
                                </div>
                              </div>
                              <div class="content">
                                <div
                                  class="body-data innerData"
                                  style="margin: -1px"
                                >
                                  <div class="data-outer">
                                    <div class="data-inner">
                                      <ul class="data-content">
                                        <li
                                          class="field"
                                          style="width: calc(100% + 1px)"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.memberDiv.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .memberInfo.memberDiv
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .memberInfo.memberDiv
                                                      .code
                                                  "
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(100% + 1px)"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.memberGrade.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .memberInfo.memberGrade
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .memberInfo.memberGrade
                                                      .code
                                                  "
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.memberName.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-text
                                                    v-model="
                                                      couponSearchOption
                                                        .memberInfo.memberName
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.memberNo.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-text v-model="couponSearchOption.memberInfo.memberNo.from"
                                                              @keyup="onKeyUpMemberNo('from')"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                          <div class="content" style="width: 20px">
                                            <ul class="row">
                                              <li class="item form-group">
                                                ~
                                              </li>
                                            </ul>
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-text v-model="couponSearchOption.memberInfo.memberNo.to"
                                                              @keyup="onKeyUpMemberNo('to')"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.sexCode.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .memberInfo.sexCode
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .memberInfo.sexCode.code
                                                  "
                                                  :selectAll="false"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.birthDay.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li
                                                class="item form-group"
                                                style="width: 50%"
                                              >
                                                <div class="form">
                                                  <input
                                                    type="number"
                                                    autocapitalize="off"
                                                    v-model="
                                                      couponSearchOption
                                                        .memberInfo.birthYy
                                                    "
                                                    max="2500"
                                                    min="1900"
                                                    class="e-input"
                                                    :placeholder="
                                                      vueCouponSearchNameObj
                                                        .memberInfo.birthYy
                                                        .title
                                                    "
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                class="item form-group"
                                                style="width: 25%"
                                              >
                                                <div class="form">
                                                  <input
                                                    type="number"
                                                    autocapitalize="off"
                                                    v-model="
                                                      couponSearchOption
                                                        .memberInfo.birthMm
                                                    "
                                                    max="12"
                                                    min="1"
                                                    class="e-input"
                                                    :placeholder="
                                                      vueCouponSearchNameObj
                                                        .memberInfo.birthMm
                                                        .title
                                                    "
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                class="item form-group"
                                                style="width: 25%"
                                              >
                                                <div class="form">
                                                  <input
                                                    type="number"
                                                    autocapitalize="off"
                                                    v-model="
                                                      couponSearchOption
                                                        .memberInfo.birthDd
                                                    "
                                                    max="31"
                                                    min="1"
                                                    class="e-input"
                                                    :placeholder="
                                                      vueCouponSearchNameObj
                                                        .memberInfo.birthDd
                                                        .title
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.ageCodes.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .memberInfo.ageCodes
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .memberInfo.ageCodes
                                                      .code
                                                  "
                                                />
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.hp4.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-text
                                                    v-model="
                                                      couponSearchOption
                                                        .memberInfo.hp4
                                                    "
                                                    maxlength="4"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.smsReceive.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .memberInfo.smsReceive
                                                  "
                                                  :is-type-list="'receive'"
                                                  :selectAll="false"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.mailReceive.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .memberInfo.mailReceive
                                                  "
                                                  :is-type-list="
                                                    vueCouponSearchNameObj
                                                      .memberInfo.mailReceive
                                                      .code
                                                  "
                                                  :selectAll="false"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.cprDiv.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .memberInfo.cprDiv
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .memberInfo.cprDiv.code
                                                  "
                                                  :selectAll="false"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.cprName.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-text
                                                    v-model="
                                                      couponSearchOption
                                                        .memberInfo.cprName
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.wrkplc.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-text
                                                    v-model="
                                                      couponSearchOption
                                                        .memberInfo.wrkplc
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.acqsDate.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-date-range
                                                    v-model="
                                                      couponSearchOption
                                                        .memberInfo.acqsDate
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.expireDate.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-date-range
                                                    v-model="
                                                      couponSearchOption
                                                        .memberInfo.expireDate
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                  .memberInfo.logCnt.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li
                                                  class="item form-group"
                                                  style="width: 50%"
                                              >
                                                <div class="form">
                                                  <input-number
                                                      v-model="
                                                      couponSearchOption
                                                        .memberInfo.logCnt
                                                    "
                                                      :allowDot="false"
                                                      :allowMinus="false"
                                                      :max="4200000000"
                                                      :propMaxLength="13"
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                  class="item form-group"
                                                  style="width: 50%"
                                              >
                                                회 이상
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.isOut.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .memberInfo.isOut
                                                  "
                                                  :is-type-list="'isOut'"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(100% + 1px)"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.membershipStatus
                                                .title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .memberInfo
                                                      .membershipStatus
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .memberInfo
                                                      .membershipStatus.code
                                                  "
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(100% + 1px)"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.entryOrder.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .memberInfo.entryOrder
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .memberInfo.entryOrder
                                                      .code
                                                  "
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(100% + 1px)"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.nearbyCode.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .memberInfo.nearbyCode
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .memberInfo.nearbyCode
                                                      .code
                                                  "
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(100% + 1px)"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj
                                                .memberInfo.areaCode.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .memberInfo.areaCode
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .memberInfo.areaCode
                                                      .code
                                                  "
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                      </ul>
                                      <div class="border-left"></div>
                                      <div class="border-right"></div>
                                      <div class="border-bottom"></div>
                                    </div>
                                  </div>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <ul class="data-content">
                            <li class="field" style="width: calc(100% + 1px)">
                              <!-- 필수 : required -->
                              <div class="content title outerDataTitle">
                                <div
                                  class="outerDataTitleItem"
                                  style="line-height: 0"
                                >
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="hasResveInfo"
                                            />
                                            <i></i>
                                            <div class="label">
                                              {{
                                                vueCouponSearchNameObj
                                                  .resveInfo.title
                                              }}
                                            </div>
                                          </label>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="content">
                                <div
                                  class="body-data innerData"
                                  style="margin: -1px"
                                >
                                  <div class="data-outer">
                                    <div class="data-inner">
                                      <ul class="data-content">
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div
                                            :class="[
                                              'title',
                                              { required: hasResveInfo },
                                            ]"
                                          >
                                            {{
                                              vueCouponSearchNameObj.resveInfo
                                                .resveDate.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-date-range
                                                    v-model="
                                                      couponSearchOption
                                                        .resveInfo.resveDate
                                                    "
                                                    :disabled="!hasResveInfo"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.resveInfo
                                                .resveTime.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li
                                                class="item form-group"
                                                style="width: calc((100% - 21px) / 2);border-right: none;"
                                              >
                                                <div class="form">
                                                  <input-time
                                                    v-model="
                                                      couponSearchOption
                                                        .resveInfo
                                                        .resveStartTime
                                                    "
                                                    :format="'HH:mm'"
                                                    :disabled="!hasResveInfo"
                                                    @change="
                                                      onChangeResveTime
                                                    "
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                class="item form-group"
                                                style="width: 21px;border-right: none;"
                                              >
                                                ~
                                              </li>
                                              <li
                                                class="item form-group"
                                                style="width: calc((100% - 21px) / 2);"
                                              >
                                                <div class="form">
                                                  <input-time
                                                    v-model="
                                                      couponSearchOption
                                                        .resveInfo
                                                        .resveEndTime
                                                    "
                                                    :format="'HH:mm'"
                                                    :disabled="!hasResveInfo"
                                                    @change="
                                                      onChangeResveTime
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.resveInfo
                                                .bsnCode.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .resveInfo.bsnCode
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .resveInfo.bsnCode.code
                                                  "
                                                  :except-code="['CLOSE']"
                                                  :disabled="!hasResveInfo"
                                                  :selectAll="false"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(((100% / 3) * 2) + 1px);"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.resveInfo
                                                .dwCode.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .resveInfo.dwCode
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .resveInfo.dwCode.code
                                                  "
                                                  :disabled="!hasResveInfo"
                                                  :textColor="true"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.resveInfo
                                                .resveCnt.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li
                                                class="item form-group"
                                                style="width: 50%"
                                              >
                                                <div class="form">
                                                  <input-number
                                                    v-model="
                                                      couponSearchOption
                                                        .resveInfo.resveCnt
                                                    "
                                                    :allowDot="false"
                                                    :allowMinus="false"
                                                    :max="4200000000"
                                                    :propMaxLength="10"
                                                    :disabled="!hasResveInfo"
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                class="item form-group"
                                                style="width: 50%"
                                              >
                                                회 이상
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(100% + 1px)"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.resveInfo
                                                .resveChannel.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .resveInfo.resveChannel
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .resveInfo.resveChannel
                                                      .code
                                                  "
                                                  :disabled="!hasResveInfo"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="content title">
                                            <ul class="row">
                                              <li
                                                class="item form-group check"
                                              >
                                                <ul class="check">
                                                  <li>
                                                    <label>
                                                      <input
                                                        type="checkbox"
                                                        v-model="
                                                          couponSearchOption
                                                            .resveInfo
                                                            .resveExFlag
                                                        "
                                                        :disabled="
                                                          !hasResveInfo
                                                        "
                                                        @change="
                                                          onChangeResveExFlag
                                                        "
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        {{
                                                          vueCouponSearchNameObj
                                                            .resveInfo
                                                            .resveExFlag.title
                                                        }}
                                                      </div>
                                                    </label>
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-date-range
                                                    v-model="
                                                      couponSearchOption
                                                        .resveInfo.resveExDate
                                                    "
                                                    :disabled="
                                                      !hasResveInfo ||
                                                      !couponSearchOption
                                                        .resveInfo.resveExFlag
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="content title">
                                            <ul class="row">
                                              <li
                                                class="item form-group check"
                                              >
                                                <ul class="check">
                                                  <li>
                                                    <label>
                                                      <input
                                                        type="checkbox"
                                                        v-model="
                                                          couponSearchOption
                                                            .resveInfo
                                                            .resvePenaltyFlag
                                                        "
                                                        :disabled="
                                                          !hasResveInfo
                                                        "
                                                        @change="
                                                          onChangeResvePenaltyFlag
                                                        "
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        {{
                                                          vueCouponSearchNameObj
                                                            .resveInfo
                                                            .resvePenaltyFlag
                                                            .title
                                                        }}
                                                      </div>
                                                    </label>
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-date-range
                                                    v-model="
                                                      couponSearchOption
                                                        .resveInfo
                                                        .resvePenaltyDate
                                                    "
                                                    :disabled="
                                                      !hasResveInfo ||
                                                      !couponSearchOption
                                                        .resveInfo
                                                        .resvePenaltyFlag
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(100% + 1px)"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.resveInfo
                                                .resveKind.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .resveInfo.resveKind
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .resveInfo.resveKind
                                                      .code
                                                  "
                                                  :disabled="!hasResveInfo"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                      </ul>
                                      <div class="border-left"></div>
                                      <div class="border-right"></div>
                                      <div class="border-bottom"></div>
                                    </div>
                                  </div>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <ul class="data-content">
                            <li class="field" style="width: calc(100% + 1px)">
                              <!-- 필수 : required -->
                              <div class="content title outerDataTitle">
                                <div
                                  class="outerDataTitleItem"
                                  style="line-height: 0"
                                >
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="hasVisitInfo"
                                            />
                                            <i></i>
                                            <div class="label">
                                              {{
                                                vueCouponSearchNameObj
                                                  .visitInfo.title
                                              }}
                                            </div>
                                          </label>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="content">
                                <div
                                  class="body-data innerData"
                                  style="margin: -1px"
                                >
                                  <div class="data-outer">
                                    <div class="data-inner">
                                      <ul class="data-content">
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div
                                            :class="[
                                              'title',
                                              { required: hasVisitInfo },
                                            ]"
                                          >
                                            {{
                                              vueCouponSearchNameObj.visitInfo
                                                .visitDate.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-date-range
                                                    v-model="
                                                      couponSearchOption
                                                        .visitInfo.visitDate
                                                    "
                                                    :disabled="!hasVisitInfo"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.visitInfo
                                                .visitTime.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li
                                                class="item form-group"
                                                style="width: calc((100% - 21px) / 2);border-right: none;"
                                              >
                                                <div class="form">
                                                  <input-time
                                                    v-model="
                                                      couponSearchOption
                                                        .visitInfo
                                                        .visitStartTime
                                                    "
                                                    :format="'HH:mm'"
                                                    :disabled="!hasVisitInfo"
                                                    @change="
                                                      onChangeVisitTime
                                                    "
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                class="item form-group"
                                                style="width: 21px;border-right: none;"
                                              >
                                                ~
                                              </li>
                                              <li
                                                class="item form-group"
                                                style="width: calc((100% - 21px) / 2);"
                                              >
                                                <div class="form">
                                                  <input-time
                                                    v-model="
                                                      couponSearchOption
                                                        .visitInfo
                                                        .visitEndTime
                                                    "
                                                    :format="'HH:mm'"
                                                    :disabled="!hasVisitInfo"
                                                    @change="
                                                      onChangeVisitTime
                                                    "
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.visitInfo
                                                .bsnCode.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .visitInfo.bsnCode
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .visitInfo.bsnCode.code
                                                  "
                                                  :except-code="['CLOSE']"
                                                  :disabled="!hasVisitInfo"
                                                  :selectAll="false"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(((100% / 3) * 2) + 1px);"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.visitInfo
                                                .dwCode.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .visitInfo.dwCode
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .visitInfo.dwCode.code
                                                  "
                                                  :disabled="!hasVisitInfo"
                                                  :textColor="true"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.visitInfo
                                                .visitCnt.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li
                                                class="item form-group"
                                                style="width: 50%"
                                              >
                                                <div class="form">
                                                  <input-number
                                                    v-model="
                                                      couponSearchOption
                                                        .visitInfo.visitCnt
                                                    "
                                                    :allowDot="false"
                                                    :allowMinus="false"
                                                    :max="4200000000"
                                                    :propMaxLength="10"
                                                    :disabled="!hasVisitInfo"
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                class="item form-group"
                                                style="width: 50%"
                                              >
                                                회 이상
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(100% + 1px)"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.visitInfo
                                                .paymtCode.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .visitInfo.paymtCode
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .visitInfo.paymtCode
                                                      .code
                                                  "
                                                  :disabled="!hasVisitInfo"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                      </ul>
                                      <div class="border-left"></div>
                                      <div class="border-right"></div>
                                      <div class="border-bottom"></div>
                                    </div>
                                  </div>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <ul class="data-content">
                            <li class="field" style="width: calc(100% + 1px)">
                              <!-- 필수 : required -->
                              <div class="content title outerDataTitle">
                                <div
                                  class="outerDataTitleItem"
                                  style="line-height: 0"
                                >
                                  <ul class="row">
                                    <li class="item form-group check">
                                      <ul class="check">
                                        <li>
                                          <label>
                                            <input
                                              type="checkbox"
                                              v-model="hasSalesInfo"
                                            />
                                            <i></i>
                                            <div class="label">
                                              {{
                                                vueCouponSearchNameObj
                                                  .salesInfo.title
                                              }}
                                            </div>
                                          </label>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="content">
                                <div
                                  class="body-data innerData"
                                  style="margin: -1px"
                                >
                                  <div class="data-outer">
                                    <div class="data-inner">
                                      <ul class="data-content">
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div
                                            :class="[
                                              'title',
                                              { required: hasSalesInfo },
                                            ]"
                                          >
                                            {{
                                              vueCouponSearchNameObj.salesInfo
                                                .salesDate.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <div class="form">
                                                  <input-date-range
                                                    v-model="
                                                      couponSearchOption
                                                        .salesInfo.salesDate
                                                    "
                                                    :disabled="!hasSalesInfo"
                                                  />
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.salesInfo
                                                .bsnCode.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .salesInfo.bsnCode
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .salesInfo.bsnCode.code
                                                  "
                                                  :except-code="['CLOSE']"
                                                  :disabled="!hasSalesInfo"
                                                  :selectAll="false"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li
                                          class="field"
                                          style="width: calc(100% + 1px)"
                                        >
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.salesInfo
                                                .storeCode.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <check-box-row
                                                  v-model="
                                                    couponSearchOption
                                                      .salesInfo.storeCode
                                                  "
                                                  :code="
                                                    vueCouponSearchNameObj
                                                      .salesInfo.storeCode
                                                      .code
                                                  "
                                                  :except-code="
                                                    this.exceptStoreCode
                                                  "
                                                  :disabled="!hasSalesInfo"
                                                ></check-box-row>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div
                                            :class="[
                                              'title',
                                              { required: hasSalesInfo },
                                            ]"
                                          >
                                            {{
                                              vueCouponSearchNameObj.salesInfo
                                                .salesDiv.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li class="item form-group">
                                                <ul class="check">
                                                  <li>
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        id="saleDivP"
                                                        value="P"
                                                        v-model="
                                                          couponSearchOption
                                                            .salesInfo
                                                            .salesDiv
                                                        "
                                                        :disabled="
                                                          !hasSalesInfo
                                                        "
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        개인매출
                                                      </div>
                                                    </label>
                                                  </li>
                                                  <li>
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        id="saleDivT"
                                                        value="T"
                                                        v-model="
                                                          couponSearchOption
                                                            .salesInfo
                                                            .salesDiv
                                                        "
                                                        :disabled="
                                                          !hasSalesInfo
                                                        "
                                                      />
                                                      <i></i>
                                                      <div class="label">
                                                        팀매출
                                                      </div>
                                                    </label>
                                                  </li>
                                                </ul>
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.salesInfo
                                                .amt.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li
                                                class="item form-group"
                                                style="width: 50%"
                                              >
                                                <div class="form">
                                                  <input-number
                                                    v-model="
                                                      couponSearchOption
                                                        .salesInfo.amt
                                                    "
                                                    :allowDot="false"
                                                    :allowMinus="false"
                                                    :propMaxLength="13"
                                                    :disabled="!hasSalesInfo"
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                class="item form-group"
                                                style="width: 50%"
                                              >
                                                원 이상
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                        <li class="field">
                                          <!-- 필수 : required -->
                                          <div class="title">
                                            {{
                                              vueCouponSearchNameObj.salesInfo
                                                .unitPrice.title
                                            }}
                                          </div>
                                          <div class="content">
                                            <ul class="row">
                                              <li
                                                class="item form-group"
                                                style="width: 50%"
                                              >
                                                <div class="form">
                                                  <input-number
                                                    v-model="
                                                      couponSearchOption
                                                        .salesInfo.unitPrice
                                                    "
                                                    :allowDot="false"
                                                    :allowMinus="false"
                                                    :propMaxLength="13"
                                                    :disabled="!hasSalesInfo"
                                                  />
                                                </div>
                                              </li>
                                              <li
                                                class="item form-group"
                                                style="width: 50%"
                                              >
                                                원 이상
                                              </li>
                                            </ul>
                                          </div>
                                        </li>
                                      </ul>
                                      <div class="border-left"></div>
                                      <div class="border-right"></div>
                                      <div class="border-bottom"></div>
                                    </div>
                                  </div>
                                  <div class="border-left"></div>
                                  <div class="border-right"></div>
                                  <div class="border-bottom"></div>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                    <erp-button
                      button-div="CLOSE"
                      ref="submitBtn"
                      v-show="false"
                      type="submit"
                    ></erp-button>
                  </div>
                </section>
              </article>
            </div>
            <div style="border-top: 1px solid #ccc;display: flex;flex-direction: row;">
              <div style="box-sizing: border-box;float: left;line-height: 19px;width: 82px;min-height: 27px;padding: 5px 5px 3px 5px;border-right: 1px solid #e0e0e0;display: flex;">
                <div style="width: 100%; text-align: center">검색조건</div>
              </div>
              <div style="box-sizing: border-box;float: left;line-height: 19px;width: calc(100% - 82px);min-height: 27px;padding: 5px 5px 3px 5px;flex: 1;">
                <div
                  v-for="(selectedData, i) in selectedData"
                  :key="selectedData.name"
                  style="float: left; padding: 0 4px"
                >
                  <div
                    style="float: left"
                    v-if="
                      i <
                      (isSearchSectionVisible && isMemberGridVisible ? 3 : 100)
                    "
                  >
                    {{ selectedData.name }} : {{ selectedData.data }}
                  </div>
                  <erp-button
                    button-div="DELETE"
                    style="float: left;cursor: pointer;border: none;background: none;margin: 0;padding: 3px 6px 5px 6px;outline: none;"
                    :use-syncfusion-component-style="false"
                    @click="
                      deleteSelectedData(
                        selectedData.key,
                        selectedData.innerKey
                      )
                    "
                  >
                    <div class="iconClose"></div>
                  </erp-button>
                  <div style="clear: both; height: 0" />
                </div>
                <span
                  v-if="
                    isSearchSectionVisible &&
                    isMemberGridVisible &&
                    selectedData.length > 3
                  "
                  >... 외 {{ selectedData.length - 3 }}건</span
                >
              </div>
              <div style="clear: both; height: 0" />
            </div>
          </div>
        </section>
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section
          :class="[
            'article-section',
            'section-02',
            'accordion',
            { close: !isMemberGridVisible },
            { open: isSearchSectionVisible },
          ]"
        >
          <div class="section-header">
            <div class="header-left">
              <div class="header-title" @click="handleMemberGridHeaderClick">
                회원 조건 정보
              </div>
              <div class="header-caption">
                [ {{ totalMembers | numberWithCommas }} 건 ]
              </div>
            </div>
            <div class="header-caption"></div>
          </div>
          <div class="section-body" style="border: none">
            <grid
              ref="couponMemberSearchGrid"
              id="couponMemberSearchGrid"
              v-bind="memberGridProps"
              @cellClick="couponMemberSearchGridCellClick"
              @filtered="onMemberGridDataFiltered"
              @sorted="onMemberGridDataFiltered"
              @paged="onMemberGridDataFiltered"
            />
          </div>
        </section>
      </article>
    </div>
    <batch-sms-send-popup
      ref="batchSmsSendPopup"
      v-if="isBatchSmsSendPopupOpen"
      @popupClosed="batchSmsSendPopupClosed"
      :three-depth="'MembershipConditionsSearch'"
    />
    <coupon-pub-popup
      ref="couponPubPopup"
      v-if="isCouponPubPopupOpen"
      @popupClosed="couponPupPopupClosed"
    />
    <detail-visit-info-in-coupon-member-search-popup
      ref="detailVisitInfoInCouponMemberSearchPopup"
      v-if="isDetailVisitInfoInCouponMemberSearchPopup"
      @popupClosed="detailVisitInfoInCouponMemberSearchPopupClosed"
    />
  </div>
</template>

<style scoped>
.iconClose{display:block;float:left;width:11px;height:11px;border-radius:50%;background:#666 url(../../assets/images/common/multiselect-close.png) no-repeat center center}
.body-data.outerData + .body-data.outerData{margin-top:-1px}
.body-data.outerData .title.outerDataTitle{display:flex;align-items:center;border-top:1px solid #000}
.body-data.outerData .title.outerDataTitle .row{border-bottom:none;display:inline-block;line-height:1.5}
.body-data.outerData .title.outerDataTitle .item .check::before{display:none}
.body-data.outerData .title.outerDataTitle .outerDataTitleItem{width:100%;text-align:center}
.body-data.innerData{border-top:1px solid #000}
.body-data.innerData .data-content .field{width:calc((100% / 3) + 1px)}
.body-data.innerData .data-content .field .title{text-align:right}
.body-data.innerData .data-content .field .content.title ul.row{float:right}
.body-data.innerData .data-content .field .content.title li.item{padding-right:12px}
.body-data.innerData .data-content .field .content.title .item .check::before{display:none}
.body-data.innerData .data-content .field .content.title .item .check .label{color:#000}
body .appContent .article-section.section-01.accordion.close > .section-body{flex:1;border:1px solid #ccc;border-top:1px solid #ccc!important}
body .appContent .article-section.section-02.accordion .header-title{background-image:url(../../assets/images/common/accordion-bottom.png)}
body .appContent .article-section.section-02.accordion.close .header-title{background-image:url(../../assets/images/common/accordion-top.png)}
body .appContent .body-article .article-section.section-01.close{height:186px}
body .appContent .body-article .article-section.section-01.open{height:calc(100% - 46px)}
body .appContent .body-article .article-section.section-01:not(.close){height:75px}
body .appContent .body-article .article-section.section-01:not(.close) > .section-body{border-top:0;overflow:hidden}
body .appContent .body-article .article-section.section-01:not(.close) > .section-body button{display:none}
body .appContent .body-article .article-section.section-02{height:calc(100% - 186px)}
body .appContent .body-article .article-section.section-02.open{height:calc(100% - 75px)}
body .article-section.accordion.close > .section-header .header-caption,body .article-section.accordion.close > .section-header .header-text,body .article-section.accordion.close > .section-header .header-label,body .article-section.accordion.close > .section-header .header-check,body .article-section.accordion.close > .section-header .header-button,body .article-section.accordion.close > .section-header .header-switch{display:block}
</style>

<script>
import Vue from "vue";
import AsyncComputed from "vue-async-computed";
import CheckBoxRow from "@/components/common/CheckBoxRow";
import InputNumber from "@/components/common/InputNumber";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import InputTime from "@/components/common/datetime/InputTime";
import moment from "moment";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import BatchSmsSendPopup from "@/views/common/BatchSmsSendPopup";
import GolfERPService from "@/service/GolfERPService";
import { couponSearchNameObj } from "@/utils/couponInitData";
import CouponPubPopup from "@/views/member-management/CouponPubPopup";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { numberWithCommas } from "@/utils/number";
import { mapActions, mapGetters } from "vuex";
import Grid from "@/components/grid/Grid";
import ErpButton from "@/components/button/ErpButton.vue";
import {
  FORMAT_MEMBER_NUMBER,
  FORMAT_NUMBER,
  FORMAT_TEL_CELLPHONE_NUMBER,
} from "@/components/grid/GridCellDataFormatUtil";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import DetailVisitInfoInCouponMemberSearchPopup from "@/views/member-management/popup/DetailVisitInfoInCouponMemberSearchPopup";
import {fetchMaxMemberNo} from "@/api/v2/reports/Report";
import {replace} from "lodash";

Vue.use(AsyncComputed);

moment.locale("ko");

const couponSearchInitOption = {
  memberInfo: {
    memberDiv: [],
    memberGrade: [],
    memberName: null,
    memberNo: { from: null, to: null },
    sexCode: [],
    birthDay: null,
    birthYy: null,
    birthMm: null,
    birthDd: null,
    ageCodes: [],
    hp4: null,
    smsReceive: ["Y"],
    mailReceive: [],
    cprDiv: [],
    cprName: null,
    wrkplc: null,
    acqsDate: { from: null, to: null },
    expireDate: { from: null, to: null },
    logCnt: null,
    isOut: ["N"],
    membershipStatus: [],
    entryOrder: [],
    nearbyCode: [],
    areaCode: [],
  },
  resveInfo: {
    resveDate: { from: null, to: null },
    resveStartTime: null,
    resveEndTime: null,
    bsnCode: [],
    dwCode: [],
    resveCnt: null,
    resveChannel: [],
    resveKind: [],
    resveExFlag: false,
    resveExDate: { from: null, to: null },
    resvePenaltyFlag: false,
    resvePenaltyDate: { from: null, to: null },
  },
  visitInfo: {
    visitDate: { from: null, to: null },
    visitStartTime: null,
    visitEndTime: null,
    bsnCode: [],
    dwCode: [],
    visitCnt: null,
    paymtCode: [],
  },
  salesInfo: {
    salesDate: { from: null, to: null },
    bsnCode: [],
    storeCode: [],
    salesDiv: "P",
    amt: null,
    unitPrice: null,
  },
  exclude: {
    members: [],
  },
};

export default {
  name: "CouponMemberSearch",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  filters: {
    numberWithCommas,
  },
  components: {
    Grid,
    InputText,
    CouponPubPopup,
    InputTime,
    InputDateRange,
    InputNumber,
    CheckBoxRow,
    BatchSmsSendPopup,
    ErpButton,
    DetailVisitInfoInCouponMemberSearchPopup,
  },
  watch: {
    couponSearchOption: {
      deep: true,
      handler(data) {
        this.selectedData = [];
        Object.keys(data).forEach((key) => {
          if (
            !((key === "visitInfo" && !this.hasVisitInfo) ||
            (key === "resveInfo" && !this.hasResveInfo) ||
            (key === "salesInfo" && !this.hasSalesInfo))
          ) {
            Object.entries(data[key]).forEach(([innerKey, value]) => {
              couponSearchNameObj[key][innerKey].dataSet = value;
              const data = couponSearchNameObj[key][innerKey].value;
              if (data) {
                const selectedObj = {
                  name:
                    couponSearchNameObj[key].title +
                    ">" +
                    couponSearchNameObj[key][innerKey].title,
                  key,
                  innerKey,
                  data,
                };
                this.selectedData.push(selectedObj);
              }
            });
          }
        });
      },
    },
    hasVisitInfo: {
      immediate: true,
      handler() {
        this.couponSearchOption.visitInfo = JSON.parse(
          JSON.stringify(couponSearchInitOption.visitInfo)
        );
      },
    },
    hasResveInfo: {
      immediate: true,
      handler() {
        this.couponSearchOption.resveInfo = JSON.parse(
          JSON.stringify(couponSearchInitOption.resveInfo)
        );
      },
    },
    hasSalesInfo: {
      immediate: true,
      handler() {
        this.couponSearchOption.salesInfo = JSON.parse(
          JSON.stringify(couponSearchInitOption.salesInfo)
        );
      },
    },
    "couponSearchOption.memberInfo.birthYy": {
      handler(data) {
        if (data.length > 4) {
          this.couponSearchOption.memberInfo.birthYy = data.slice(0, 4);
        }
      },
    },
    "couponSearchOption.memberInfo.birthMm": {
      handler(data) {
        if (data.length > 2) {
          this.couponSearchOption.memberInfo.birthMm = data.slice(0, 2);
        }
      },
    },
    "couponSearchOption.memberInfo.birthDd": {
      handler(data) {
        if (data.length > 2) {
          this.couponSearchOption.memberInfo.birthDd = data.slice(0, 2);
        }
      },
    },
  },
  asyncComputed: {
    async exceptStoreCode() {
      const response = await GolfERPService.fetchStores(true);
      return commonCodesGetCommonCode("STORE_CODE")
        .filter((data) => {
          return !response.find((rData) => rData.code === data.comCode);
        })
        .map((data) => data.comCode);
    },
  },
  computed: {
    ...mapGetters(["username"]),
    ...mapGetters("documents", ["isCouponMemberExcelDownloading"]),
    isPopupOpened() {
      return this.isBatchSmsSendPopupOpen || this.isCouponPubPopupOpen || this.isDetailVisitInfoInCouponMemberSearchPopup;
    },
    datetimeString() {
      return moment().format(`YYYY-MM-DD HH:mm:ss`);
    },
    memberGridProps() {
      let columns = [
        {
          name: "회원정보",
          columns: [
            {
              minWidth: 16,
              name: "회원명",
              width: 90,
              field: "memberName",
              type: String,
              cellClass: () => [this.$t('className.grid.deleteHoverArea')],
            },
            {
              minWidth: 16,
              name: "회원구분",
              width: 90,
              field: "memberDivisionComName",
              type: String,
              cellStyle: {
                textAlign: "center",
              },
            },
            {
              minWidth: 16,
              name: "회원번호",
              width: 90,
              field: "memberNumber",
              type: String,
              format: FORMAT_MEMBER_NUMBER,
              cellStyle: {
                textAlign: "center",
              },
            },
            {
              minWidth: 16,
              name: "회원등급",
              width: 90,
              field: "memberGradeComName",
              type: String,
              cellStyle: {
                textAlign: "center",
              },
            },
            {
              minWidth: 16,
              name: "연락처",
              width: 100,
              sortable: false,
              filterable: false,
              field: "memberPhoneNumber",
              type: String,
              format: FORMAT_TEL_CELLPHONE_NUMBER,
              cellStyle: {
                textAlign: "center",
              },
            },
            {
              minWidth: 16,
              name: "성별",
              width: 65,
              field: "memberGenderComName",
              type: String,
              cellStyle: {
                textAlign: "center",
              },
            },
            {
              minWidth: 16,
              name: "생년월일",
              width: 90,
              field: "memberBirthday",
              type: Date,
              cellStyle: {
                textAlign: "center",
              },
            },
            {
              minWidth: 16,
              name: "연령",
              width: 60,
              field: "memberAge",
              type: Number,
              cellStyle: {
                textAlign: "center",
              },
            },
            {
              minWidth: 16,
              name: "회원권 상태",
              width: 110,
              field: "membershipStatusComName",
              type: String,
              cellStyle: {
                textAlign: "center",
              },
            },
            {
              minWidth: 16,
              name: "취득일자",
              width: 90,
              field: "acquireDate",
              type: Date,
              cellStyle: {
                textAlign: "center",
              },
            },
            {
              minWidth: 16,
              name: "만기일자",
              width: 90,
              field: "expireDate",
              type: Date,
              cellStyle: {
                textAlign: "center",
              },
            },
            {
              minWidth: 16,
              name: "법인구분",
              width: 90,
              field: "corporateDivisionComName",
              type: String,
              cellStyle: {
                textAlign: "center",
              },
            },
            {
              minWidth: 16,
              name: "법인명",
              width: 140,
              field: "corporationName",
              type: String,
            },
            {
              minWidth: 16,
              name: "지역민구분",
              width: 100,
              field: "memberNearbyCodeComName",
              type: String,
              cellStyle: {
                textAlign: "center",
              },
            },
            {
              minWidth: 16,
              name: "지역구분",
              width: 100,
              field: "memberDetailsAreaCodeComName",
              type: String,
              cellStyle: {
                textAlign: "center",
              },
            },
            {
              minWidth: 16,
              name: "집 주소1",
              width: 250,
              field: "memberDetailHomeAddr1",
              type: String,
            },
            {
              minWidth: 16,
              name: "집 주소2",
              width: 200,
              field: "memberDetailHomeAddr2",
              type: String,
            },
            {
              minWidth: 16,
              name: "근무처",
              width: 140,
              field: "memberWorkPlace",
              type: String,
            },
            {
              minWidth: 16,
              name: "SMS 수신",
              width: 110,
              field: "memberSmsReceiptDivisionComName",
              type: String,
              cellStyle: {
                textAlign: "center",
              },
            },
            {
              minWidth: 16,
              name: "접속횟수",
              width: 90,
              field: "webIdInformationConnectCount",
              type: Number,
              format: FORMAT_NUMBER,
              cellStyle: {
                textAlign: "center",
              },
            },
          ],
        },
        {
          name: "예약정보",
          columns: [
            {
              minWidth: 16,
              name: "주중",
              width: 90,
              sortable:
                this.couponSearchOption.resveInfo.resveDate.from &&
                this.couponSearchOption.resveInfo.resveDate.to,
              filterable:
                this.couponSearchOption.resveInfo.resveDate.from &&
                this.couponSearchOption.resveInfo.resveDate.to,
              field: "reservationWeekDayCount",
              type: Number,
              format: FORMAT_NUMBER,
              cellStyle: {
                textAlign: "right",
              },
            },
            {
              minWidth: 16,
              name: "주말",
              width: 90,
              sortable:
                this.couponSearchOption.resveInfo.resveDate.from &&
                this.couponSearchOption.resveInfo.resveDate.to,
              filterable:
                this.couponSearchOption.resveInfo.resveDate.from &&
                this.couponSearchOption.resveInfo.resveDate.to,
              field: "reservationWeekEndCount",
              type: Number,
              format: FORMAT_NUMBER,
              cellStyle: {
                textAlign: "right",
              },
            },
            {
              minWidth: 16,
              name: "(합계)",
              width: 90,
              sortable:
                this.couponSearchOption.resveInfo.resveDate.from &&
                this.couponSearchOption.resveInfo.resveDate.to,
              filterable:
                this.couponSearchOption.resveInfo.resveDate.from &&
                this.couponSearchOption.resveInfo.resveDate.to,
              field: "reservationWeekTotalCount",
              type: Number,
              format: FORMAT_NUMBER,
              cellStyle: {
                textAlign: "right",
              },
            },
          ],
        },
        {
          name: "내장정보",
          columns: [
            {
              minWidth: 16,
              name: "주중",
              width: 90,
              sortable:
                this.couponSearchOption.visitInfo.visitDate.from &&
                this.couponSearchOption.visitInfo.visitDate.to,
              filterable:
                this.couponSearchOption.visitInfo.visitDate.from &&
                this.couponSearchOption.visitInfo.visitDate.to,
              field: "visitWeekDayCount",
              type: Number,
              format: FORMAT_NUMBER,
              class: () => [this.$t("className.grid.clickArea")],
              cellClass: (data) => {
                return data.visitWeekDayCount ? [this.$t("className.grid.clickArea")] : [];
              },
              cellStyle: {
                textAlign: "right",
              },
            },
            {
              minWidth: 16,
              name: "주말",
              width: 90,
              sortable:
                this.couponSearchOption.visitInfo.visitDate.from &&
                this.couponSearchOption.visitInfo.visitDate.to,
              filterable:
                this.couponSearchOption.visitInfo.visitDate.from &&
                this.couponSearchOption.visitInfo.visitDate.to,
              field: "visitWeekEndCount",
              type: Number,
              format: FORMAT_NUMBER,
              class: () => [this.$t("className.grid.clickArea")],
              cellClass: (data) => {
                return data.visitWeekEndCount ? [this.$t("className.grid.clickArea")] : [];
              },
              cellStyle: {
                textAlign: "right",
              },
            },
            {
              minWidth: 16,
              name: "(합계)",
              width: 90,
              sortable:
                this.couponSearchOption.visitInfo.visitDate.from &&
                this.couponSearchOption.visitInfo.visitDate.to,
              filterable:
                this.couponSearchOption.visitInfo.visitDate.from &&
                this.couponSearchOption.visitInfo.visitDate.to,
              field: "visitWeekTotalCount",
              type: Number,
              format: FORMAT_NUMBER,
              class: () => [this.$t("className.grid.clickArea")],
              cellClass: (data) => {
                return data.visitWeekTotalCount ? [this.$t("className.grid.clickArea")] : [];
              },
              cellStyle: {
                textAlign: "right",
              },
            },
          ],
        },
        {
          name: "매출정보",
          columns: [
            {
              minWidth: 16,
              name: "인원 수",
              width: 90,
              sortable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              filterable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              field: "salesPersonCount",
              type: Number,
              format: FORMAT_NUMBER,
              cellStyle: {
                textAlign: "right",
              },
            },
            {
              minWidth: 16,
              name: "그린피",
              width: 90,
              sortable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              filterable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              field: "salesFrontAmount",
              type: Number,
              format: FORMAT_NUMBER,
              cellStyle: {
                textAlign: "right",
              },
            },
            {
              minWidth: 16,
              name: "카트대여",
              width: 90,
              sortable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              filterable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              field: "salesCartAmount",
              type: Number,
              format: FORMAT_NUMBER,
              cellStyle: {
                textAlign: "right",
              },
            },
            {
              minWidth: 16,
              name: "일반대여",
              width: 90,
              sortable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              filterable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              field: "salesRentAmount",
              type: Number,
              format: FORMAT_NUMBER,
              cellStyle: {
                textAlign: "right",
              },
            },
            {
              name: "식음",
              columns: [
                {
                  minWidth: 16,
                  name: "대식당",
                  width: 90,
                  sortable:
                    this.couponSearchOption.salesInfo.salesDate.from &&
                    this.couponSearchOption.salesInfo.salesDate.to,
                  filterable:
                    this.couponSearchOption.salesInfo.salesDate.from &&
                    this.couponSearchOption.salesInfo.salesDate.to,
                  field: "salesGrillAmount",
                  type: Number,
                  format: FORMAT_NUMBER,
                  cellStyle: {
                    textAlign: "right",
                  },
                },
                {
                  minWidth: 16,
                  name: "그늘집",
                  width: 90,
                  sortable:
                    this.couponSearchOption.salesInfo.salesDate.from &&
                    this.couponSearchOption.salesInfo.salesDate.to,
                  filterable:
                    this.couponSearchOption.salesInfo.salesDate.from &&
                    this.couponSearchOption.salesInfo.salesDate.to,
                  field: "salesTeeAmount",
                  type: Number,
                  format: FORMAT_NUMBER,
                  cellStyle: {
                    textAlign: "right",
                  },
                },
                {
                  minWidth: 16,
                  name: "(합계)",
                  width: 90,
                  sortable:
                    this.couponSearchOption.salesInfo.salesDate.from &&
                    this.couponSearchOption.salesInfo.salesDate.to,
                  filterable:
                    this.couponSearchOption.salesInfo.salesDate.from &&
                    this.couponSearchOption.salesInfo.salesDate.to,
                  field: "salesGrillAndTeeAmount",
                  type: Number,
                  format: FORMAT_NUMBER,
                  cellStyle: {
                    textAlign: "right",
                  },
                },
              ],
            },
            {
              minWidth: 16,
              name: "프로샵",
              width: 90,
              sortable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              filterable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              field: "salesShopAmount",
              type: Number,
              format: FORMAT_NUMBER,
              cellStyle: {
                textAlign: "right",
              },
            },
            {
              minWidth: 16,
              name: "기타",
              width: 90,
              sortable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              filterable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              field: "salesEtcAmount",
              type: Number,
              format: FORMAT_NUMBER,
              cellStyle: {
                textAlign: "right",
              },
            },
            {
              minWidth: 16,
              name: "매출합계",
              width: 90,
              sortable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              filterable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              field: "salesTotalAmount",
              type: Number,
              format: FORMAT_NUMBER,
              cellStyle: {
                textAlign: "right",
              },
            },
            {
              minWidth: 16,
              name: "객단가",
              width: 90,
              sortable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              filterable:
                this.couponSearchOption.salesInfo.salesDate.from &&
                this.couponSearchOption.salesInfo.salesDate.to,
              field: "salesUnitPrice",
              type: Number,
              format: FORMAT_NUMBER,
              cellStyle: {
                textAlign: "right",
              },
            },
          ],
        },
      ];

      if (!this.hasResveInfo) {
        columns = columns.filter((item) => item.name !== "예약정보");
      }
      if (!this.hasVisitInfo) {
        columns = columns.filter((item) => item.name !== "내장정보");
      }
      if (!this.hasSalesInfo) {
        columns = columns.filter((item) => item.name !== "매출정보");
      }

      return {
        columns,
        records: this.members,
        visibleRowNumberColumn: true,
        sortable: this.isSearched,
        filterable: this.isSearched,
        sizeOfRecordPerPage: 50,
        countOfAllRecords: this.totalMembers,
        rangeOfPages: 10,
      };
    },
    membersDivisionMultiSelectProps() {
      const dataSource = commonCodesGetCommonCode("MEMBER_DIV").map(
        ({ comCode: value, comName: text }) => ({
          value,
          text,
        })
      );
      return {
        allowFiltering: false,
        dataSource,
        fields: {
          text: "text",
          value: "value",
        },
        placeholder: "전체",
      };
    },
  },
  mounted() {
    this.searchInit();
  },
  data() {
    return {
      isLoading: false,
      isSearched: false,
      isSearchSectionVisible: false,
      isMemberGridVisible: true,
      isBatchSmsSendPopupOpen: false,
      isCouponPubPopupOpen: false,
      isDetailVisitInfoInCouponMemberSearchPopup: false,
      hasResveInfo: false,
      hasVisitInfo: false,
      hasSalesInfo: false,
      totalMembers: 0,
      members: [],
      selectedData: [],
      vueCouponSearchNameObj: JSON.parse(JSON.stringify(couponSearchNameObj)),
      couponSearchOption: JSON.parse(JSON.stringify(couponSearchInitOption)),
    };
  },
  methods: {
    ...mapActions("documents", ["downloadExcelMembers"]),

    //회원번호 포멧
    onKeyUpMemberNo(key) {
      const memberNo = (this.couponSearchOption.memberInfo.memberNo[key])?.trim()?.replace(/-/gi, "")?.substr(0,8);
      const returnText = memberNo.match(/^([\w]{0,2})([\w]{0,4})([\w]{0,2})([\w]{0,2})$/);
      this.couponSearchOption.memberInfo.memberNo[key] = returnText[1] + (returnText[2] ? `-${returnText[2]}` : ``) + (returnText[3] ? `-${returnText[3]}` : ``);
    },
    handleSearchSectionHeaderClick() {
      this.isSearchSectionVisible = !this.isSearchSectionVisible;
      if (this.isSearchSectionVisible) {
        this.isMemberGridVisible = true;
      }
    },
    handleMemberGridHeaderClick() {
      this.isMemberGridVisible = !this.isMemberGridVisible;
      if (!this.isMemberGridVisible) {
        this.isSearchSectionVisible = false;
      }
    },
    couponPupPopupClosed() {
      this.isCouponPubPopupOpen = false;
    },
    detailVisitInfoInCouponMemberSearchPopupClosed() {
      this.isDetailVisitInfoInCouponMemberSearchPopup = false;
    },
    async searchInit() {
      this.couponSearchOption = JSON.parse(
        JSON.stringify(couponSearchInitOption)
      );
      this.couponSearchOption.memberInfo.isOut = ["N"];
      this.hasResveInfo = false;
      this.hasVisitInfo = false;
      this.hasSalesInfo = false;
      this.couponSearchOption.salesInfo.salesDiv = null;

      const maxMemberNo = await GolfErpAPI.getMaxMemberNo();
      this.couponSearchOption.memberInfo.memberNo.from = this.formatMemberNo('00000000');
      this.couponSearchOption.memberInfo.memberNo.to = this.formatMemberNo(maxMemberNo);
    },
    formatMemberNo(memberNo) {
      return memberNo.replace(/^(\d{2})(\d{4})(\d{2})$/, '$1-$2-$3');
    },
    sendSms() {
      const selected = this.members.map((data) => {
        return {
          sendKey: data.memberId,
          contactTel: data.memberPhoneNumber,
          name: data.memberName,
        };
      });

      if (!(selected.length > 0)) {
        return this.errorToast("SMS 전송할 자료가 없습니다.");
      }

      const tmpData = {
        smsSendType: "NOTE",
        smsDiv: "DEEP_MEMBER",
        smsSendList: Array.from(new Set(selected.map((d) => d.sendKey))).map(
          (sendKey) => {
            return {
              sendKey,
              receiverName: selected.find((s) => s.sendKey === sendKey).name,
              name: selected.find((s) => s.sendKey === sendKey).name,
              contactTel: selected.find((s) => s.sendKey === sendKey)
                .contactTel,
            };
          }
        ),
      };

      const result = this.makeSearchOptions();

      this.isBatchSmsSendPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.batchSmsSendPopup.showBatchSmsSendPopup(tmpData, result);
      });
    },
    async applyCoupon() {
      if (this.totalMembers < 1) {
        return this.errorToast("발급대상이 없습니다");
      }

      const result = this.makeSearchOptions();

      this.isCouponPubPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.couponPubPopup.showPopup(this.totalMembers, result);
      });
    },
    batchSmsSendPopupClosed() {
      this.isBatchSmsSendPopupOpen = false;
    },
    deleteSelectedData(key, innerKey) {
      this.couponSearchOption[key][innerKey] = JSON.parse(
        JSON.stringify(couponSearchInitOption[key][innerKey])
      );

      if (key === "resveInfo" && innerKey === "resveDate") {
        this.hasResveInfo = false;
      }
      if (key === "visitInfo" && innerKey === "visitDate") {
        this.hasVisitInfo = false;
      }
      if (key === "salesInfo") {
        if (innerKey === "salesDate") {
          this.hasSalesInfo = false;
        } else if (innerKey === "salesDiv") {
          this.couponSearchOption.salesInfo.salesDiv = null;
        }
      }
    },
    onMemberGridDataFiltered(event) {
      if (!event) {
        return;
      }

      this.searchMembers();
    },
    onChangeResveTime() {
      if (
        (!this.couponSearchOption.resveInfo.resveStartTime ||
          this.couponSearchOption.resveInfo.resveStartTime === "00:00") &&
        (!this.couponSearchOption.resveInfo.resveEndTime ||
          this.couponSearchOption.resveInfo.resveEndTime === "00:00")
      ) {
        this.couponSearchOption.resveInfo.resveStartTime = null;
        this.couponSearchOption.resveInfo.resveEndTime = null;
      }
    },
    onChangeVisitTime() {
      if (
        (!this.couponSearchOption.visitInfo.visitStartTime ||
          this.couponSearchOption.visitInfo.visitStartTime === "00:00") &&
        (!this.couponSearchOption.visitInfo.visitEndTime ||
          this.couponSearchOption.visitInfo.visitEndTime === "00:00")
      ) {
        this.couponSearchOption.visitInfo.visitStartTime = null;
        this.couponSearchOption.visitInfo.visitEndTime = null;
      }
    },
    onChangeResveExFlag() {
      if (!this.couponSearchOption.resveInfo.resveExFlag) {
        this.couponSearchOption.resveInfo.resveExDate.from = null;
        this.couponSearchOption.resveInfo.resveExDate.to = null;
      }
    },
    onChangeResvePenaltyFlag() {
      if (!this.couponSearchOption.resveInfo.resvePenaltyFlag) {
        this.couponSearchOption.resveInfo.resvePenaltyDate.from = null;
        this.couponSearchOption.resveInfo.resvePenaltyDate.to = null;
      }
    },
    async onSearchClick() {
      this.$refs["couponMemberSearchGrid"].resetPage();
      await this.searchMembers();

      if (
        this.$refs["couponMemberSearchGrid"].getSelectedRows().length < 1
      ) {
        this.$refs["couponMemberSearchGrid"].selectRow(0);
      }
    },
    makeSearchOptions() {
      const limit = this.memberGridProps.sizeOfRecordPerPage;
      const { page, filters, orders } = this.$refs[
        "couponMemberSearchGrid"
      ].getOrdersAndFiltersAndPage();

      const _orders = JSON.parse(JSON.stringify(orders || []));
      if (_orders.findIndex(({ field }) => field === "memberName") < 0) {
        _orders.push({
          field: "memberName",
          direction: "asc",
        });
      }

      const genderList = this.couponSearchOption.memberInfo.sexCode;
      const gender = genderList.length === 1 ? genderList.join("") : undefined;
      const smsReceiveList = this.couponSearchOption.memberInfo.smsReceive;
      const smsReceive =
        smsReceiveList.length === 1
          ? !!smsReceiveList.includes("Y")
          : undefined;
      const mailReceiveList = this.couponSearchOption.memberInfo.mailReceive;
      const mailReceive =
        mailReceiveList.length === 1
          ? !!mailReceiveList.includes("Y")
          : undefined;
      const cprDivList = this.couponSearchOption.memberInfo.cprDiv;
      const cprDiv = cprDivList.length === 1 ? cprDivList.join("") : undefined;
      const isOutList = this.couponSearchOption.memberInfo.isOut;
      const isOut =
        isOutList.length === 1 ? !!isOutList.includes("Y") : undefined;
      const resveBsnCodeList = this.couponSearchOption.resveInfo.bsnCode;
      const resveBsnCode =
        resveBsnCodeList.length === 1 ? resveBsnCodeList.join("") : undefined;
      const visitBsnCodeList = this.couponSearchOption.visitInfo.bsnCode;
      const visitBsnCode =
        visitBsnCodeList.length === 1 ? visitBsnCodeList.join("") : undefined;
      const salesBsnCodeList = this.couponSearchOption.salesInfo.bsnCode;
      const salesBsnCode =
        salesBsnCodeList.length === 1 ? salesBsnCodeList.join("") : undefined;
      const excludeMembersByMembershipId = this.couponSearchOption.exclude.members.map(item => item.membershipId);
      return {
        memberDivisions:
          this.couponSearchOption.memberInfo.memberDiv?.length > 0 &&
          this.couponSearchOption.memberInfo.memberDiv?.length !==
            commonCodesGetCommonCode("MEMBER_DIV")?.length
            ? this.couponSearchOption.memberInfo.memberDiv
            : undefined,
        memberGrades:
          this.couponSearchOption.memberInfo.memberGrade?.length > 0 &&
          this.couponSearchOption.memberInfo.memberGrade?.length !==
            commonCodesGetCommonCode("MEMBER_GRADE")?.length
            ? this.couponSearchOption.memberInfo.memberGrade
            : undefined,
        memberName: this.couponSearchOption.memberInfo.memberName || undefined,
        memberNumberFrom: this.couponSearchOption.memberInfo.memberNo.from || undefined,
        memberNumberTo: this.couponSearchOption.memberInfo.memberNo.to || undefined,
        gender,
        birthYear: this.couponSearchOption.memberInfo.birthYy || undefined,
        birthMonth: this.couponSearchOption.memberInfo.birthMm || undefined,
        birthDay: this.couponSearchOption.memberInfo.birthDd || undefined,
        ageCodes:
          this.couponSearchOption.memberInfo.ageCodes?.length > 0 &&
          this.couponSearchOption.memberInfo.ageCodes?.length !==
            commonCodesGetCommonCode("AGE_DISTRB")?.length
            ? this.couponSearchOption.memberInfo.ageCodes
            : undefined,
        endOfHpNumber: this.couponSearchOption.memberInfo.hp4 || undefined,
        allowSmsReceiptDivision: smsReceive,
        allowReceiptEmail: mailReceive,
        corporationDivision: cprDiv,
        corporationName:
          this.couponSearchOption.memberInfo.cprName || undefined,
        workPlace: this.couponSearchOption.memberInfo.wrkplc || undefined,
        acquireDateFrom: this.couponSearchOption.memberInfo.acqsDate.from || undefined,
        acquireDateTo: this.couponSearchOption.memberInfo.acqsDate.to || undefined,
        loginCount: this.couponSearchOption.memberInfo.logCnt || undefined,
        expireDateFrom: this.couponSearchOption.memberInfo.expireDate.from || undefined,
        expireDateTo: this.couponSearchOption.memberInfo.expireDate.to || undefined,
        isOut,
        membershipStatus:
          this.couponSearchOption.memberInfo.membershipStatus?.length > 0 &&
          this.couponSearchOption.memberInfo.membershipStatus?.length !==
            commonCodesGetCommonCode("MEMBERSHIP_STATUS")?.length
            ? this.couponSearchOption.memberInfo.membershipStatus
            : undefined,
        entryOrders:
          this.couponSearchOption.memberInfo.entryOrder?.length > 0 &&
          this.couponSearchOption.memberInfo.entryOrder?.length !==
            commonCodesGetCommonCode("ENTRY_ORDER")?.length
            ? this.couponSearchOption.memberInfo.entryOrder
            : undefined,
        nearbyCodes:
          this.couponSearchOption.memberInfo.nearbyCode?.length > 0
            ? this.couponSearchOption.memberInfo.nearbyCode
            : undefined,
        areaCodes:
          this.couponSearchOption.memberInfo.areaCode?.length > 0 &&
          this.couponSearchOption.memberInfo.areaCode?.length !==
            commonCodesGetCommonCode("AREA_CODE")?.length
            ? this.couponSearchOption.memberInfo.areaCode
            : undefined,

        reservationDateFrom:
          (this.hasResveInfo &&
            this.couponSearchOption.resveInfo.resveDate.from) ||
          undefined,
        reservationDateTo:
          (this.hasResveInfo &&
            this.couponSearchOption.resveInfo.resveDate.to) ||
          undefined,
        reservationTimeFrom:
          (this.hasResveInfo &&
            this.couponSearchOption.resveInfo.resveStartTime) ||
          undefined,
        reservationTimeTo:
          (this.hasResveInfo &&
            this.couponSearchOption.resveInfo.resveEndTime) ||
          undefined,
        reservationBsnCode: (this.hasResveInfo && resveBsnCode) || undefined,
        reservationDayOfWeeks:
          (this.hasResveInfo &&
            (this.couponSearchOption.resveInfo.dwCode?.length > 0 &&
            this.couponSearchOption.resveInfo.dwCode?.length !==
              commonCodesGetCommonCode("DW_CODE")?.length
              ? this.couponSearchOption.resveInfo.dwCode
              : undefined)) ||
          undefined,
        reservationCount:
          (this.hasResveInfo && this.couponSearchOption.resveInfo.resveCnt) ||
          undefined,
        reservationChannels:
          (this.hasResveInfo &&
            (this.couponSearchOption.resveInfo.resveChannel?.length > 0 &&
            this.couponSearchOption.resveInfo.resveChannel?.length !==
              commonCodesGetCommonCode("RESVE_CHANNEL")?.length
              ? this.couponSearchOption.resveInfo.resveChannel
              : undefined)) ||
          undefined,
        excludeExistReservationDateFrom:
          (this.hasResveInfo &&
            this.couponSearchOption.resveInfo.resveExFlag &&
            this.couponSearchOption.resveInfo.resveExDate.from) ||
          undefined,
        excludeExistReservationDateTo:
          (this.hasResveInfo &&
            this.couponSearchOption.resveInfo.resveExFlag &&
            this.couponSearchOption.resveInfo.resveExDate.to) ||
          undefined,
        excludePenaltyReservationDateFrom:
          (this.hasResveInfo &&
            this.couponSearchOption.resveInfo.resvePenaltyFlag &&
            this.couponSearchOption.resveInfo.resvePenaltyDate.from) ||
          undefined,
        excludePenaltyReservationDateTo:
          (this.hasResveInfo &&
            this.couponSearchOption.resveInfo.resvePenaltyFlag &&
            this.couponSearchOption.resveInfo.resvePenaltyDate.to) ||
          undefined,
        reservationKinds:
          (this.hasResveInfo &&
            (this.couponSearchOption.resveInfo.resveKind?.length > 0 &&
            this.couponSearchOption.resveInfo.resveKind?.length !==
              commonCodesGetCommonCode("RESVE_KIND")?.length
              ? this.couponSearchOption.resveInfo.resveKind
              : undefined)) ||
          undefined,

        visitDateFrom:
          (this.hasVisitInfo &&
            this.couponSearchOption.visitInfo.visitDate.from) ||
          undefined,
        visitDateTo:
          (this.hasVisitInfo &&
            this.couponSearchOption.visitInfo.visitDate.to) ||
          undefined,
        visitTimeFrom:
          (this.hasVisitInfo &&
            this.couponSearchOption.visitInfo.visitStartTime) ||
          undefined,
        visitTimeTo:
          (this.hasVisitInfo &&
            this.couponSearchOption.visitInfo.visitEndTime) ||
          undefined,
        visitBsnCode: (this.hasVisitInfo && visitBsnCode) || undefined,
        visitDayOfWeeks:
          (this.hasVisitInfo &&
            (this.couponSearchOption.visitInfo.dwCode.length > 0 &&
            this.couponSearchOption.visitInfo.dwCode?.length !==
              commonCodesGetCommonCode("DW_CODE")?.length
              ? this.couponSearchOption.visitInfo.dwCode
              : undefined)) ||
          undefined,
        visitCount:
          (this.hasVisitInfo && this.couponSearchOption.visitInfo.visitCnt) ||
          undefined,
        paymentCodes:
          (this.hasVisitInfo &&
            (this.couponSearchOption.visitInfo.paymtCode.length > 0 &&
            this.couponSearchOption.visitInfo.paymtCode?.length !==
              commonCodesGetCommonCode("PAYMT_CODE")?.length
              ? this.couponSearchOption.visitInfo.paymtCode
              : undefined)) ||
          undefined,

        salesDateFrom:
          (this.hasSalesInfo &&
            this.couponSearchOption.salesInfo.salesDate.from) ||
          undefined,
        salesDateTo:
          (this.hasSalesInfo &&
            this.couponSearchOption.salesInfo.salesDate.to) ||
          undefined,
        salesBsnCode: (this.hasSalesInfo && salesBsnCode) || undefined,
        storeCodes:
          (this.hasSalesInfo &&
            (this.couponSearchOption.salesInfo.storeCode.length > 0 &&
            this.couponSearchOption.salesInfo.storeCode?.length !==
              commonCodesGetCommonCode("STORE_CODE")?.length
              ? this.couponSearchOption.salesInfo.storeCode
              : undefined)) ||
          undefined,
        fitOrTeamSalesDivision:
          (this.hasSalesInfo && this.couponSearchOption.salesInfo.salesDiv) ||
          undefined,
        salesAmount:
          (this.hasSalesInfo && this.couponSearchOption.salesInfo.amt) ||
          undefined,
        unitPrice:
          (this.hasSalesInfo && this.couponSearchOption.salesInfo.unitPrice) ||
          undefined,
        excludeMembersByMembershipId: excludeMembersByMembershipId.length > 0 ? excludeMembersByMembershipId : undefined,

        limit,
        offset: (page - 1) * limit,
        filters: filters.length > 0 ? filters : undefined,
        orders: _orders.length > 0 ? _orders : undefined,
      };
    },
    async searchMembers() {
      if (!this.couponSearchOption.memberInfo.memberNo.from || !this.couponSearchOption.memberInfo.memberNo.to) {
        return this.errorToast(this.$t("main.validationMessage.requiredMessage", ["회원번호"]));
      }

      const numMemberNoFrom = this.couponSearchOption.memberInfo.memberNo.from.replaceAll('-','');
      const numMemberNoTo = this.couponSearchOption.memberInfo.memberNo.to.replaceAll('-','');
      if (numMemberNoFrom > numMemberNoTo) {
        return this.errorToast("회원번호 FROM이 TO보다 큽니다.");
      }

      if (this.hasResveInfo) {
        // if (
        //   !this.couponSearchOption.resveInfo.resveDate.from ||
        //   !this.couponSearchOption.resveInfo.resveDate.to
        // ) {
        //   return this.errorToast(
        //     this.$t("main.validationMessage.requiredMessage", ["예약일자"])
        //   );
        // }
        if (
          this.couponSearchOption.resveInfo.resveExFlag &&
          (!this.couponSearchOption.resveInfo.resveExDate.from ||
            !this.couponSearchOption.resveInfo.resveExDate.to)
        ) {
          return this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["기예약제외"])
          );
        }
        if (
          this.couponSearchOption.resveInfo.resvePenaltyFlag &&
          (!this.couponSearchOption.resveInfo.resvePenaltyDate.from ||
            !this.couponSearchOption.resveInfo.resvePenaltyDate.to)
        ) {
          return this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["위약제외"])
          );
        }
      }
      if (
        this.hasVisitInfo &&
        (!this.couponSearchOption.visitInfo.visitDate.from ||
          !this.couponSearchOption.visitInfo.visitDate.to)
      ) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["내장일자"])
        );
      }
      if (this.hasSalesInfo) {
        if (
          !this.couponSearchOption.salesInfo.salesDate.from ||
          !this.couponSearchOption.salesInfo.salesDate.to
        ) {
          return this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["매출일자"])
          );
        }
        if (!this.couponSearchOption.salesInfo.salesDiv) {
          return this.errorToast(
            this.$t("main.validationMessage.requiredMessage", ["매출구분"])
          );
        }
      }

      if (this.isLoading) {
        return this.errorToast("데이터를 불러오는 중입니다.");
      }

      this.isLoading = true;

      try {
        const searchOptions = this.makeSearchOptions();
        const result = await GolfErpAPI.fetchRecordsAndCountMembers(
          searchOptions
        );

        this.totalMembers = result.total;
        this.members = result.records;
        this.isSearched = true;
        this.isMemberGridVisible = true;
      } catch (e) {
        console.error(e);
      }

      this.isLoading = false;
    },
    async onExcelClick() {
      if (50000 < this.totalMembers) {
        this.errorToast("데이터가 너무 많습니다. 5만건 이하로 시도해주세요.");
        return;
      }
      const { filters, orders } = this.$refs[
        "couponMemberSearchGrid"
      ].getOrdersAndFiltersAndPage();

      const _orders = JSON.parse(JSON.stringify(orders || []));
      if (_orders.findIndex(({ field }) => field === "memberName") < 0) {
        _orders.push({
          field: "memberName",
          direction: "asc",
        });
      }
      const columns = this.$refs["couponMemberSearchGrid"].getColumns();

      const genderList = this.couponSearchOption.memberInfo.sexCode;
      const gender = genderList.length === 1 ? genderList.join("") : undefined;
      const smsReceiveList = this.couponSearchOption.memberInfo.smsReceive;
      const smsReceive =
        smsReceiveList.length === 1
          ? !!smsReceiveList.includes("Y")
          : undefined;
      const mailReceiveList = this.couponSearchOption.memberInfo.mailReceive;
      const mailReceive =
        mailReceiveList.length === 1
          ? !!mailReceiveList.includes("Y")
          : undefined;
      const cprDivList = this.couponSearchOption.memberInfo.cprDiv;
      const cprDiv = cprDivList.length === 1 ? cprDivList.join("") : undefined;
      const isOutList = this.couponSearchOption.memberInfo.isOut;
      const isOut =
        isOutList.length === 1 ? !!isOutList.includes("Y") : undefined;
      const resveBsnCodeList = this.couponSearchOption.resveInfo.bsnCode;
      const resveBsnCode =
        resveBsnCodeList.length === 1 ? resveBsnCodeList.join("") : undefined;
      const visitBsnCodeList = this.couponSearchOption.visitInfo.bsnCode;
      const visitBsnCode =
        visitBsnCodeList.length === 1 ? visitBsnCodeList.join("") : undefined;
      const salesBsnCodeList = this.couponSearchOption.salesInfo.bsnCode;
      const salesBsnCode =
        salesBsnCodeList.length === 1 ? salesBsnCodeList.join("") : undefined;
      const excludeMembersByMembershipId = this.couponSearchOption.exclude.members.map(item => item.membershipId);

      this.downloadExcelMembers({
        payload: {
          memberDivisions:
            this.couponSearchOption.memberInfo.memberDiv?.length > 0 &&
            this.couponSearchOption.memberInfo.memberDiv?.length !==
              commonCodesGetCommonCode("MEMBER_DIV")?.length
              ? this.couponSearchOption.memberInfo.memberDiv
              : undefined,
          memberGrades:
            this.couponSearchOption.memberInfo.memberGrade?.length > 0 &&
            this.couponSearchOption.memberInfo.memberGrade?.length !==
              commonCodesGetCommonCode("MEMBER_GRADE")?.length
              ? this.couponSearchOption.memberInfo.memberGrade
              : undefined,
          memberName:
            this.couponSearchOption.memberInfo.memberName || undefined,
          memberNumberFrom:
            this.couponSearchOption.memberInfo.memberNo.from || undefined,
          memberNumberTo:
            this.couponSearchOption.memberInfo.memberNo.to || undefined,
          gender,
          birthYear: this.couponSearchOption.memberInfo.birthYy || undefined,
          birthMonth: this.couponSearchOption.memberInfo.birthMm || undefined,
          birthDay: this.couponSearchOption.memberInfo.birthDd || undefined,
          ageCodes:
            this.couponSearchOption.memberInfo.ageCodes?.length > 0 &&
            this.couponSearchOption.memberInfo.ageCodes?.length !==
              commonCodesGetCommonCode("AGE_DISTRB")?.length
              ? this.couponSearchOption.memberInfo.ageCodes
              : undefined,
          endOfHpNumber: this.couponSearchOption.memberInfo.hp4 || undefined,
          allowSmsReceiptDivision: smsReceive,
          allowReceiptEmail: mailReceive,
          corporationDivision: cprDiv,
          corporationName:
            this.couponSearchOption.memberInfo.cprName || undefined,
          workPlace: this.couponSearchOption.memberInfo.wrkplc || undefined,
          acquireDateFrom:
            this.couponSearchOption.memberInfo.acqsDate.from || undefined,
          acquireDateTo:
            this.couponSearchOption.memberInfo.acqsDate.to || undefined,
          loginCount: this.couponSearchOption.memberInfo.logCnt || undefined,
          expireDateFrom: this.couponSearchOption.memberInfo.expireDate.from || undefined,
          expireDateTo: this.couponSearchOption.memberInfo.expireDate.to || undefined,
          isOut,
          membershipStatus:
            this.couponSearchOption.memberInfo.membershipStatus?.length > 0 &&
            this.couponSearchOption.memberInfo.membershipStatus?.length !==
              commonCodesGetCommonCode("MEMBERSHIP_STATUS")?.length
              ? this.couponSearchOption.memberInfo.membershipStatus
              : undefined,
          entryOrders:
            this.couponSearchOption.memberInfo.entryOrder?.length > 0 &&
            this.couponSearchOption.memberInfo.entryOrder?.length !==
              commonCodesGetCommonCode("ENTRY_ORDER")?.length
              ? this.couponSearchOption.memberInfo.entryOrder
              : undefined,
          nearbyCodes:
            this.couponSearchOption.memberInfo.nearbyCode?.length > 0
              ? this.couponSearchOption.memberInfo.nearbyCode
              : undefined,
          areaCodes:
            this.couponSearchOption.memberInfo.areaCode?.length > 0 &&
            this.couponSearchOption.memberInfo.areaCode?.length !==
              commonCodesGetCommonCode("AREA_CODE")?.length
              ? this.couponSearchOption.memberInfo.areaCode
              : undefined,

          reservationDateFrom:
            (this.hasResveInfo &&
              this.couponSearchOption.resveInfo.resveDate.from) ||
            undefined,
          reservationDateTo:
            (this.hasResveInfo &&
              this.couponSearchOption.resveInfo.resveDate.to) ||
            undefined,
          reservationTimeFrom:
            (this.hasResveInfo &&
              this.couponSearchOption.resveInfo.resveStartTime) ||
            undefined,
          reservationTimeTo:
            (this.hasResveInfo &&
              this.couponSearchOption.resveInfo.resveEndTime) ||
            undefined,
          reservationBsnCode: (this.hasResveInfo && resveBsnCode) || undefined,
          reservationDayOfWeeks:
            (this.hasResveInfo &&
              (this.couponSearchOption.resveInfo.dwCode?.length > 0 &&
              this.couponSearchOption.resveInfo.dwCode?.length !==
                commonCodesGetCommonCode("DW_CODE")?.length
                ? this.couponSearchOption.resveInfo.dwCode
                : undefined)) ||
            undefined,
          reservationCount:
            (this.hasResveInfo && this.couponSearchOption.resveInfo.resveCnt) ||
            undefined,
          reservationChannels:
            (this.hasResveInfo &&
              (this.couponSearchOption.resveInfo.resveChannel?.length > 0 &&
              this.couponSearchOption.resveInfo.resveChannel?.length !==
                commonCodesGetCommonCode("RESVE_CHANNEL")?.length
                ? this.couponSearchOption.resveInfo.resveChannel
                : undefined)) ||
            undefined,
          excludeExistReservationDateFrom:
            (this.hasResveInfo &&
              this.couponSearchOption.resveInfo.resveExFlag &&
              this.couponSearchOption.resveInfo.resveExDate.from) ||
            undefined,
          excludeExistReservationDateTo:
            (this.hasResveInfo &&
              this.couponSearchOption.resveInfo.resveExFlag &&
              this.couponSearchOption.resveInfo.resveExDate.to) ||
            undefined,
          excludePenaltyReservationDateFrom:
            (this.hasResveInfo &&
              this.couponSearchOption.resveInfo.resvePenaltyFlag &&
              this.couponSearchOption.resveInfo.resvePenaltyDate.from) ||
            undefined,
          excludePenaltyReservationDateTo:
            (this.hasResveInfo &&
              this.couponSearchOption.resveInfo.resvePenaltyFlag &&
              this.couponSearchOption.resveInfo.resvePenaltyDate.to) ||
            undefined,
          reservationKinds:
            (this.hasResveInfo &&
              (this.couponSearchOption.resveInfo.resveKind?.length > 0 &&
              this.couponSearchOption.resveInfo.resveKind?.length !==
                commonCodesGetCommonCode("RESVE_KIND")?.length
                ? this.couponSearchOption.resveInfo.resveKind
                : undefined)) ||
            undefined,

          visitDateFrom:
            (this.hasVisitInfo &&
              this.couponSearchOption.visitInfo.visitDate.from) ||
            undefined,
          visitDateTo:
            (this.hasVisitInfo &&
              this.couponSearchOption.visitInfo.visitDate.to) ||
            undefined,
          visitTimeFrom:
            (this.hasVisitInfo &&
              this.couponSearchOption.visitInfo.visitStartTime) ||
            undefined,
          visitTimeTo:
            (this.hasVisitInfo &&
              this.couponSearchOption.visitInfo.visitEndTime) ||
            undefined,
          visitBsnCode: (this.hasVisitInfo && visitBsnCode) || undefined,
          visitDayOfWeeks:
            (this.hasVisitInfo &&
              (this.couponSearchOption.visitInfo.dwCode.length > 0 &&
              this.couponSearchOption.visitInfo.dwCode?.length !==
                commonCodesGetCommonCode("DW_CODE")?.length
                ? this.couponSearchOption.visitInfo.dwCode
                : undefined)) ||
            undefined,
          visitCount:
            (this.hasVisitInfo && this.couponSearchOption.visitInfo.visitCnt) ||
            undefined,
          paymentCodes:
            (this.hasVisitInfo &&
              (this.couponSearchOption.visitInfo.paymtCode.length > 0 &&
              this.couponSearchOption.visitInfo.paymtCode?.length !==
                commonCodesGetCommonCode("PAYMT_CODE")?.length
                ? this.couponSearchOption.visitInfo.paymtCode
                : undefined)) ||
            undefined,

          salesDateFrom:
            (this.hasSalesInfo &&
              this.couponSearchOption.salesInfo.salesDate.from) ||
            undefined,
          salesDateTo:
            (this.hasSalesInfo &&
              this.couponSearchOption.salesInfo.salesDate.to) ||
            undefined,
          salesBsnCode: (this.hasSalesInfo && salesBsnCode) || undefined,
          storeCodes:
            (this.hasSalesInfo &&
              (this.couponSearchOption.salesInfo.storeCode.length > 0 &&
              this.couponSearchOption.salesInfo.storeCode?.length !==
                commonCodesGetCommonCode("STORE_CODE")?.length
                ? this.couponSearchOption.salesInfo.storeCode
                : undefined)) ||
            undefined,
          fitOrTeamSalesDivision:
            (this.hasSalesInfo && this.couponSearchOption.salesInfo.salesDiv) ||
            undefined,
          salesAmount:
            (this.hasSalesInfo && this.couponSearchOption.salesInfo.amt) ||
            undefined,
          unitPrice:
            (this.hasSalesInfo &&
              this.couponSearchOption.salesInfo.unitPrice) ||
            undefined,
          excludeMembersByMembershipId: excludeMembersByMembershipId.length > 0 ? excludeMembersByMembershipId : undefined,

          filters: filters.length > 0 ? filters : undefined,
          orders: _orders.length > 0 ? _orders : undefined,
          columns,
        },
      });
    },
    couponMemberSearchGridCellClick(event, payload) {
      const {
        column: {
          field,
        },
        record,
      } = payload;

      if (field === "memberName") {
        const findIndex = this.couponSearchOption.exclude.members.findIndex(item => item.membershipId === record.id);

        if (findIndex < 0) {
          this.couponSearchOption.exclude.members.push({
            membershipId: record.id,
            memberName: record.memberName,
          });

          this.onSearchClick();
        }
      } else if (["visitWeekDayCount","visitWeekEndCount","visitWeekTotalCount"].includes(field)) {
        if (!(record[field] > 0)) {
          return;
        }

        const membershipId = record.id;
        const visitDateFrom = this.couponSearchOption.visitInfo.visitDate.from;
        const visitDateTo = this.couponSearchOption.visitInfo.visitDate.to;
        const visitStartTime = this.couponSearchOption.visitInfo.visitStartTime;
        const visitEndTime = this.couponSearchOption.visitInfo.visitEndTime;
        const bsnCodes = field === "visitWeekDayCount" ? ["WEEKDAY"] : field === "visitWeekEndCount" ? ["WEEKEND"] : [];
        const dwCodes = this.couponSearchOption.visitInfo.dwCode;

        this.isDetailVisitInfoInCouponMemberSearchPopup = true;
        this.$nextTick(() => {
          this.$refs.detailVisitInfoInCouponMemberSearchPopup.showPopup({
            membershipId: membershipId,
            bsnDateFrom: visitDateFrom,
            bsnDateTo: visitDateTo,
            visitStartTime: visitStartTime,
            visitEndTime: visitEndTime,
            bsnCodes: bsnCodes,
            dwCodes: dwCodes,
          });
        });
      }
    }
  },
};
</script>
